const state = {
  stage: null,
  stageWidth: null,
  stageHeight: null,
  cans: [],
  ripples: [],
  mouseX: 0,
  mouseY: 0,
  dragInterval: null,
  lastClick: Date.now(),
  isMouseDown: false,
  CAN_CELL_WIDTH: 0,
  CAN_CELL_HEIGHT: 0,
  CAN_RATIO: 5 / 3,
  DEBUG: false,
}

const colors = [
  '#ffc600', // 'Young Mango'
  '#ff8672', // 'Extra Peach'
  '#dcc7b7', // 'Toasted Coconut'
  '#006098', // 'Sour Blueberry'
  '#752e4a', // 'Blackberry Jam'
  '#ddb5c8', // 'White Grape'
  '#ffd720', // 'Lemon Verbena'
  '#93d500', // 'Pear Elderflower'
  '#f4436c', // 'Strawberry Basil'
  '#ff8300', // 'Orange Nectarine'
  '#4a9462', // 'Gingery Ale'
  '#db0032', // 'Cherry Pop'
]

const colorGroups = [
  [
    '#4a9462', // 'Gingery Ale'
    '#db0032', // 'Cherry Pop'
    '#ff8300', // 'Orange Nectarine'
  ],
  [
    '#ffc600', // 'Young Mango'
    '#ff8672', // 'Extra Peach'
    '#dcc7b7', // 'Toasted Coconut'
  ],
  [
    '#ffd720', // 'Lemon Verbena'
    '#93d500', // 'Pear Elderflower'
    '#f4436c', // 'Strawberry Basil'
  ],
  [
    '#006098', // 'Sour Blueberry'
    '#752e4a', // 'Blackberry Jam'
    '#ddb5c8', // 'White Grape'
  ],
]

const tween = {
  easeInCubic: (t, b, _c, d) => {
    var c = _c - b
    return c * (t /= d) * t * t + b
  },
  easeInQuad: (t, b, _c, d) => {
    var c = _c - b
    return c * (t /= d) * t + b
  },
}

class Can {
  constructor(node) {
    this.node = node
    this.animation = node.querySelector('animateTransform')
    this.rect = node.getBoundingClientRect()
    this.targetScale = 1
    this.scale = 1
    this.opacity = 1
    this.fillOpacity = 0
    this.inHoverRadius = false
    this.inOuterRippleRadius = false
    this.inInnerRippleRadius = false
    this.originalColor = this.node.getAttribute('fill')
    this.color = this.node.getAttribute('fill')
    this.colorGroup = []
    this.canAnimate = true

    this.animation.addEventListener('beginEvent', () => {
      this.canAnimate = false
    })

    this.animation.addEventListener('endEvent', () => {
      this.canAnimate = true
    })
  }

  update() {
    if (this.inHoverRadius) {
      this.fillOpacity = 1
    }

    if (this.inOuterRippleRadius) {
      this.fillOpacity = 1
    }

    if (!this.inHoverRadius && this.fillOpacity > 0 && this.colorGroup.length) {
      if (this.fillOpacity >= 0.8) {
        this.color = this.colorGroup[0]
      }

      if (this.fillOpacity >= 0.5 && this.fillOpacity < 0.8) {
        this.color = this.colorGroup[1]
      }

      if (this.fillOpacity < 0.5) {
        this.color = this.colorGroup[2]
      }
    }

    if (!this.inOuterRippleRadius && this.fillOpacity === 0) {
      this.color = this.originalColor
    }

    this.node.setAttribute('fill', this.color)
    this.node.style.fillOpacity = this.fillOpacity

    this.checkHoverProximity()
    this.checkRippleProximity()
    this.decay()
  }

  checkHoverProximity() {
    let distance = this.rect.height

    if (state.mouseX && state.mouseY) {
      let dX = this.rect.x - state.mouseX + this.rect.width / 2
      let dY = this.rect.y - state.mouseY + this.rect.height / 2

      distance = Math.hypot(dX, dY)

      if (distance >= this.rect.width / state.CAN_RATIO) {
        this.inHoverRadius = false
      } else {
        this.inHoverRadius = true
      }
    } else {
      this.inHoverRadius = false
    }
  }

  checkRippleProximity() {
    let inOuterRippleRadius = false
    let inInnerRippleRadius = false

    for (let i = state.ripples.length - 1; i >= 0; i--) {
      const ripple = state.ripples[i]
      const dx = Math.max(this.rect.left - ripple.x, 0, ripple.x - this.rect.right)
      const dy = Math.max(this.rect.top - ripple.y, 0, ripple.y - this.rect.bottom)

      const distance = Math.sqrt(dx * dx + dy * dy)

      const diff = Math.abs(distance - ripple.r)

      if (distance < ripple.r) {
        inOuterRippleRadius = true
        this.color = ripple.color
        this.colorGroup = ripple.colorGroup

        if (diff > 0) {
          inInnerRippleRadius = true
        }
        break
      }
    }

    if (inInnerRippleRadius && this.canAnimate) {
      this.animation.beginElement()
    }

    this.inOuterRippleRadius = inOuterRippleRadius
    this.inInnerRippleRadius = inInnerRippleRadius
  }

  decay() {
    if (this.fillOpacity <= 0) {
      this.fillOpacity = 0
    }

    if (this.fillOpacity > 0) {
      this.fillOpacity -= 0.025
    }

    if (this.targetScale > this.scale) {
      this.scale += 0.02
    }

    if (this.targetScale < this.scale) {
      this.scale -= 0.01
    }
  }
}

class Ripple {
  constructor(x, y) {
    this.x = x
    this.y = y
    this.r = 0
    this.maxSizeFactor = getRandom([4, 6, 8, 10])
    this.fallStart = null
    this.node = null
    this.color = getRandom(colors)
    this.colorGroup = getRandom(colorGroups)
    this.fallRate = 4
    this.growStartTime = window.performance.now()

    if (state.DEBUG) {
      const ripple = document.createElement('div')
      ripple.style.position = 'absolute'
      ripple.style.border = '2px dashed gray'
      ripple.style.borderRadius = '9999px'
      ripple.style.left = `${this.x - this.r / 2}px`
      ripple.style.top = `${this.y - this.r}px`
      ripple.style.width = `${this.r}px`
      ripple.style.height = `${this.r}px`
      state.stage.appendChild(ripple)

      setTimeout(() => {
        this.node = ripple
      }, 0)
    }
  }

  update(tick) {
    if (state.DEBUG && this.node) {
      this.node.style.left = `${this.x - this.r / 2}px`
      this.node.style.top = `${this.y - this.r}px`
      this.node.style.width = `${this.r}px`
      this.node.style.height = `${this.r}px`
    }

    if (this.r >= state.CAN_CELL_WIDTH * this.maxSizeFactor) {
      if (!this.fallStart) {
        this.fallStart = this.y
      }

      this.fall()
    } else {
      if (!this.fallStart) {
        this.grow(tick)
      }
    }

    if (this.fallStart && this.y - this.fallStart > state.CAN_CELL_HEIGHT * 2) {
      this.fall()
      this.shrink()
    }

    if (this.r < 0) {
      this.die()
    }
  }

  grow(tick) {
    this.r = tween.easeInQuad(
      Math.abs(tick - this.growStartTime),
      this.r,
      state.CAN_CELL_WIDTH * this.maxSizeFactor + 1,
      750
    )
  }

  shrink() {
    this.r -= 8
  }

  fall() {
    this.y += this.fallRate
  }

  die() {
    if (this.node) {
      state.stage.removeChild(this.node)
    }

    state.ripples.splice(state.ripples.indexOf(this), 1)
  }
}

const getRandom = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

const drawCan = () => {
  const color = getRandom(colors)

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.setAttribute('viewBox', '0 0 300 500')
  svg.setAttribute('fill', color)
  svg.setAttribute('stroke', '#000')
  svg.setAttribute('stroke-width', '5')
  svg.style.fillOpacity = 0

  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  path.setAttribute(
    'd',
    'M259.1 60.4c-17.9-26.5-5.2-35.2-15.7-36.5C150 23 177.7 23 150 23c-27.7.1 0 0-93.3 1C46.3 25.3 59 33.9 41 60.4c-11.1 16.4-16.3 25.7-16 40.8V450c1 8.9 13.9 8.4 22.9 14.5 5.5 3.7 8.1 10.9 16.3 11.9 84.7.7 84.7 1.2 171.5 0 8.2-.9 10.9-8.1 16.3-11.9 9-6.1 21.8-5.6 22.9-14.5V101.3c.4-15.2-4.8-24.4-15.8-40.9z'
  )

  svg.appendChild(path)

  const animate = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform')
  animate.setAttribute('attributeName', 'transform')
  animate.setAttribute('begin', 'indefinite')
  animate.setAttribute('dur', '1s')
  animate.setAttribute('repeatCount', '1')
  animate.setAttribute('type', 'scale')
  animate.setAttribute('values', '1 1; 0 0; 1 1')
  animate.setAttribute('keyTimes', '0; 0.5; 1')
  animate.setAttribute('keySplines', '0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1')

  svg.appendChild(animate)

  state.stage.appendChild(svg)

  setTimeout(() => {
    const can = new Can(svg)
    state.cans.push(can)
  }, 0)
}

const run = () => {
  const drawScene = (tick) => {
    for (let i = 0; i < state.cans.length; i++) {
      state.cans[i].update()
    }

    for (let i = 0; i < state.ripples.length; i++) {
      state.ripples[i].update(tick)
    }

    window.requestAnimationFrame(drawScene)
  }

  window.requestAnimationFrame(drawScene)
}

const setup = () => {
  state.stageWidth = state.stage.offsetWidth
  state.stageHeight = state.stage.offsetHeight
  state.stageRect = state.stage.getBoundingClientRect()

  state.CAN_CELL_WIDTH = 20

  if (window.matchMedia('(min-width: 768px)').matches) {
    state.CAN_CELL_WIDTH = 30
  }

  state.CAN_CELL_HEIGHT = state.CAN_CELL_WIDTH * state.CAN_RATIO

  const widthNum = Math.floor(state.stageWidth / state.CAN_CELL_WIDTH)

  const heightNum = Math.floor(state.stageHeight / state.CAN_CELL_HEIGHT)
  const heightRemainder = state.stageHeight - heightNum * state.CAN_CELL_HEIGHT

  state.stage.style.height = `${state.stageHeight - heightRemainder}px`
  state.stage.style.gridTemplateColumns = `repeat(${widthNum}, 1fr)`

  state.cans = []
  state.stage.innerHTML = null

  for (let i = 0; i < widthNum * heightNum; i++) {
    drawCan()
  }
}

const createRipple = (x, y) => {
  const stageX = state.stageRect.x
  const stageY = state.stageRect.y

  const rippleX = x - stageX
  const rippleY = y - stageY

  const ripple = new Ripple(rippleX, rippleY)
  state.ripples.push(ripple)
}

const init = (stage) => {
  state.stage = stage
  state.stageWidth = stage.offsetWidth
  state.stageHeight = stage.offsetHeight
  state.stageRect = stage.getBoundingClientRect()

  setup()
  run()

  window.addEventListener('resize', setup)

  state.stage.addEventListener(
    'mousemove',
    (e) => {
      state.mouseX = e.clientX
      state.mouseY = e.clientY

      if (state.isMouseDown && !state.dragInterval) {
        createRipple(state.mouseX, state.mouseY)

        state.dragInterval = setInterval(() => {
          createRipple(state.mouseX, state.mouseY)
        }, 500)
      }
    },
    false
  )

  state.stage.addEventListener(
    'mouseout',
    () => {
      state.mouseX = null
      state.mouseY = null
    },
    false
  )

  state.stage.addEventListener('mousedown', () => {
    state.isMouseDown = true
  })

  state.stage.addEventListener(
    'mouseup',
    (e) => {
      const now = Date.now()
      state.isMouseDown = false
      clearInterval(state.dragInterval)
      state.dragInterval = null

      if (now - state.lastClick > 100) {
        createRipple(e.clientX, e.clientY)
      }

      state.lastClick = now
    },
    false
  )
}

export default {
  init,
}
