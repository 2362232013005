import Grid from './grid'

const setupStage = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const isDarkMode = urlParams.get('dark')

  if (isDarkMode) {
    document.documentElement.classList.add('is-dark')
  }

  Grid.init(document.getElementById('stage'))
}

const init = () => {
  setupStage()
}

init()
